<template>
    <div>
        <b-row class="mb-4">
            <b-col>
                <b-input-group size="sm">
                    <b-input-group-prepend is-text>
                        <b-icon icon="search" class="orange"></b-icon>
                    </b-input-group-prepend>
                    <b-form-input type="search" size="sm" :value="search_text" @input="onInputSearch($event, 'search_text')"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col>
                <b-input-group prepend="Target" size="sm">
                    <b-form-select :value="search_target" :options="optionsTargets" @input="onInputSearch($event, 'search_target')"></b-form-select> 
                </b-input-group>
            </b-col>
        </b-row> 
    </div>
</template>

<script>
import Vuex from 'vuex'


export default {
    name: 'PentestTaskSearch',
    data() {
        return {
        }
    },
    computed: {
        ...Vuex.mapState('pentest', [
            'targets',
            'search_text',
            'search_target'
        ]),
        optionsTargets() {
            var options = this.targets.map(x => ({value: x.slug, text: x.name}));
            options.unshift({value: null, text: ''});
            return options;
        }
    },
    methods: {
        ...Vuex.mapActions('pentest', [
            'updateSearchParams'
        ]),
        onInputSearch: _.debounce(function(event, field) {
            let search = {
                search_text: this.search_text, 
                search_target: this.search_target
            };
            search[field] = event;
            this.updateSearchParams(search);
            this.$emit('updated-search');
        }, 300)
    }
}
</script>