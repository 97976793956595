<template>
    <div>
        <b-modal id="modal-credential" title="Credential" hide-footer>
            <div>
                <b-form ref="form_credential" @submit.prevent="onClickSubmitForm">
                    <b-row class="mb-1">
                        <b-col cols="3" class="text-right">
                            <label>Type</label>
                        </b-col>
                        <b-col cols="9" class="text-left">
                            <b-form-select name="cred_type" :value="credentialDetail && credentialDetail.cred_type || 'plaintext'" :options="optionsTypes" size="sm" required autofocus></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3" class="text-right">
                            <label>Username</label>
                        </b-col>
                        <b-col cols="9" class="text-left">
                            <b-form-input name="username" :value="credentialDetail && credentialDetail.username || null" size="sm" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3" class="text-right">
                            <label>Password</label>
                        </b-col>
                        <b-col cols="9" class="text-left">
                            <b-form-textarea name="password" :value="credentialDetail && credentialDetail.password || null" size="sm"></b-form-textarea>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3" class="text-right">
                            <label>Target</label>
                        </b-col>
                        <b-col cols="9" class="text-left">
                            <b-form-select name="target" :value="!credentialDetail && selected_target || credentialDetail && credentialDetail.target && credentialDetail.target.id" :options="optionsTargets" @input="onInputCredentialTarget($event)" size="sm"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row class="mb-4">
                        <b-col cols="3" class="text-right">
                            <label>Service</label>
                        </b-col>
                        <b-col cols="9" class="text-left">
                            <b-form-select name="service" :value="!credentialDetail && targetServiceDetail && targetServiceDetail.service.id || credentialDetail && credentialDetail.service && credentialDetail.service.id" :options="optionsServices" size="sm"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col align="center">
                            <b-button variant="danger" size="sm" class="m-1" @click="onClickCredentialDelete" v-if="credentialDetail">Delete</b-button>
                            <b-button type="submit" variant="primary" size="sm" class="m-1" v-if="!credentialDetail">Create</b-button>
                            <b-button type="submit" variant="primary" size="sm" class="m-1" v-if="credentialDetail">Save</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Vuex from 'vuex'

export default {
    name: 'PentestCredentialOpen',
    props: {
        credential_id: Number
    },
    data() {
        return {
            optionsTypes: [
                {value: 'plaintext', text: 'Plaintext'},
                {value: 'ssh_pkey', text: 'SSH Private Key'},
                {value: 'ntlm_hash', text: 'NTLM Hash'},
                {value: 'hash', text: 'Hash'}
            ],
            form_target: null
        }
    },
    computed: {
        ...Vuex.mapState('pentest', [
            'targets',
            'services',
            'selected_target',
            'selected_target_service'
        ]),
        ...Vuex.mapGetters('pentest', [
            'getCredentialById',
            'getTargetById',
            'getTargetServiceById'
        ]),
        credentialDetail() {
            return this.getCredentialById(this.credential_id);
        },
        targetServiceDetail() {
            return this.getTargetServiceById(this.selected_target_service);
        },
        optionsTargets() {
            var options = this.targets.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: ''});
            return options;
        },
        optionsServices() {
            var options = [{value: null, text: ''}];
            if (this.form_target) {
                var target = this.getTargetById(this.form_target);
                if (target)
                    options = options.concat(target.services.map(x => ({value: x.service.id, text: x.service.name + ' (' + x.port.number + ':' + x.port.protocol + ')'})));
            } else {
                options = options.concat(this.services.map(x => ({value: x.id, text: x.name})));
            }
            return options;
        }
    },
    methods: {
        ...Vuex.mapActions('pentest', [
            'createCredential',
            'updateCredential',
            'deleteCredential',
            'updateSelectedProp'
        ]),
        onInputCredentialTarget(target_id) {
            this.form_target = target_id;
        },
        updateSelectedCredential(credential_id) {
            this.updateSelectedProp({prop: 'selected_credential', value: credential_id});
        },
        closeModal() {
            this.$bvModal.hide('modal-credential');
        },
        onClickSubmitForm() {
            var data = {
                cred_type: this.$refs.form_credential.cred_type.value,
                username: this.$refs.form_credential.username.value,
                password: this.$refs.form_credential.password.value,
                target_id: this.$refs.form_credential.target.value,
                service_id: this.$refs.form_credential.service.value
            }
            if (!this.credentialDetail) {
                this.createCredential(data).then((res) => {
                    this.updateSelectedCredential(res.id);
                    this.closeModal();
                });
            }
            else {
                this.updateCredential({credential: this.credentialDetail, data}).then((res) => {
                    this.updateSelectedCredential(res.id);
                    this.closeModal();
                });
            }
        },
        onClickCredentialDelete() {
            var is_ok = confirm("Delete Credential ?");
            if (is_ok) {
                this.deleteCredential(this.credentialDetail).then(() => {
                    this.updateSelectedCredential(null);
                    this.closeModal();
                });
            };
        }
    }
}
</script>