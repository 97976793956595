<template>
    <div>
        <b-row class="mb-4">
            <b-col>
                <b-button to="/pentest/new">New</b-button>
            </b-col>
        </b-row>
        <PentestTaskSearch @updated-search="onUpdatedSearch"/>
        <b-row>
            <b-col>
                <b-table-simple hover v-if="tasks.length">
                    <b-thead>
                        <b-tr>
                            <b-th>Attacker</b-th>
                            <b-th>Target</b-th>
                            <b-th>Service</b-th>
                            <b-th>Action</b-th>
                            <b-th>Created</b-th>
                            <b-th>State</b-th>
                            <b-th></b-th>
                            <b-th></b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="taskDetail in tasks" :key="taskDetail.id">
                            <b-td>{{ taskDetail.attacker.name }}</b-td>
                            <b-td>{{ taskDetail.target.name }}</b-td>
                            <b-td>{{ taskDetail.target_service && taskDetail.target_service.service.name + ' (' + taskDetail.target_service.port.number + ':' + taskDetail.target_service.port.protocol + ')' }}</b-td>
                            <b-td>{{ taskDetail.action.name }}</b-td>
                            <b-td><timeago :datetime="taskDetail.create_date" :auto-update="60"></timeago></b-td>
                            <b-td>{{ getTaskState(taskDetail.id) }}</b-td>
                            <b-td>
                                <router-link :to="`/pentest/tsk/${taskDetail.id}`">
                                    <b-icon icon="arrow-up-right-circle" title="Open" class="orange"></b-icon>
                                </router-link>
                            </b-td>
                            <b-td>
                                <b-icon icon="x-square" title="Delete" class="orange" @click="onClickTaskDelete(taskDetail)"></b-icon>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-pagination
                    :value="selected_page"
                    :total-rows="task_count"
                    :per-page="task_per_page"
                    align="right"
                    @change="selectPage"
                ></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Vuex from 'vuex'

import { TokenService } from '../storage/service'

import PentestTaskSearch from '@/components/PentestTaskSearch'

export default {
    name: 'PentestTaskList',
    components: {
        PentestTaskSearch
    },
    data() {
        return {
            task_per_page: 20,
            abort_controller: null
        }
    },
    computed: {
        ...Vuex.mapState('pentest', [
            'tasks',
            'task_count',
            'selected_page'
        ]),
        ...Vuex.mapGetters('pentest', [
            'getTaskResultByTaskId'
        ])
    },
    methods: {
        ...Vuex.mapActions('pentest', [
            'loadTasks',
            'loadTasksResults',
            'deleteTask',
            'updateSelectedPage'
        ]),
        onUpdatedSearch(pageNum = 1) {
            this.abortSearch();
            this.abort_controller = new AbortController();

            this.updateSelectedPage(pageNum);

            this.loadTasks(this.abort_controller.signal)
            .then(() => {
                this.abort_controller = null;
            })
            .catch(() => {
            });
        },
        pollTaskResults() {
            this.$options.interval = setInterval(() => {
                this.loadTasksResults();
            }, 2000);
        },
        getTaskState(taskId) {
            var taskResult = this.getTaskResultByTaskId(taskId);
            if (taskResult)
                return taskResult.state;
            return 'UNKNOWN'
        },
        onClickTaskDelete(taskDetail) {
            var is_ok = confirm("Delete Task ?");
            if (is_ok) {
                this.deleteTask(taskDetail);
            };
        },
        selectPage(pageNum) {
            this.updateSelectedPage(pageNum);
            this.loadTasks();
        },
        abortSearch() {
            if (this.abort_controller) {
                this.abort_controller.abort();
            }
        }
    },
    created() {
        if (TokenService.getToken()) {
            this.pollTaskResults();
        }
    },
    beforeDestroy() {
        clearInterval(this.$options.interval);
    }
}
</script>