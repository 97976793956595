<template>
    <div>
        <b-modal id="modal-attacker" title="Attacker" hide-footer>
            <div>
                <b-form ref="form_attacker" @submit.prevent="submitForm()">
                    <b-row class="mb-1">
                        <b-col cols="4" class="text-right">
                            <label>Name</label>
                        </b-col>
                        <b-col cols="8" class="text-left">
                            <b-form-input name="name" :value="attackerDetail && attackerDetail.name || null" size="sm" required autofocus></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="4" class="text-right">
                            <label>Host</label>
                        </b-col>
                        <b-col cols="8" class="text-left">
                            <b-form-input name="host" :value="attackerDetail && attackerDetail.host || null" size="sm" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="4" class="text-right">
                            <label>SSH Port</label>
                        </b-col>
                        <b-col cols="8" class="text-left">
                            <b-form-input name="ssh_port" type="number" :value="attackerDetail && attackerDetail.ssh_port || null" size="sm" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="4" class="text-right">
                            <label>SSH User</label>
                        </b-col>
                        <b-col cols="8" class="text-left">
                            <b-form-input name="ssh_username" :value="attackerDetail && attackerDetail.ssh_username || null" size="sm" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="mb-4">
                        <b-col cols="4" class="text-right">
                            <label>SSH Private Key</label>
                        </b-col>
                        <b-col cols="8" class="text-left">
                            <b-form-textarea name="ssh_pkey" :value="attackerDetail && attackerDetail.ssh_pkey || null" size="sm" required></b-form-textarea>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col align="center">
                            <b-button variant="danger" size="sm" class="m-1" @click="onClickAttackerDelete" v-if="attackerDetail">Delete</b-button>
                            <b-button type="submit" variant="primary" size="sm" class="m-1" v-if="!attackerDetail">Create</b-button>
                            <b-button type="submit" variant="primary" size="sm" class="m-1" v-if="attackerDetail">Save</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Vuex from 'vuex'
import slugify from 'slugify';

export default {
    name: 'PentestAttackerOpen',
    props: {
        attacker_id: Number
    },
    data() {
        return {
        }
    },
    computed: {
        ...Vuex.mapGetters('pentest', [
            'getAttackerById'
        ]),
        attackerDetail() {
            return this.getAttackerById(this.attacker_id);
        }
    },
    methods: {
        ...Vuex.mapActions('pentest', [
            'createAttacker',
            'updateAttacker',
            'deleteAttacker',
            'updateSelectedProp'
        ]),
        updateSelectedAttacker(attacker_id) {
            this.updateSelectedProp({prop: 'selected_attacker', value: attacker_id});
        },
        closeModal() {
            this.$bvModal.hide('modal-attacker');
        },
        submitForm() {
            var data = {
                name: this.$refs.form_attacker.name.value,
                host: this.$refs.form_attacker.host.value,
                ssh_port: this.$refs.form_attacker.ssh_port.value,
                ssh_username: this.$refs.form_attacker.ssh_username.value,
                ssh_pkey: this.$refs.form_attacker.ssh_pkey.value
            }
            data.slug = slugify(data.name, {'replacement': '-', 'lower': true});
            if (!this.attackerDetail) {
                this.createAttacker(data).then((res) => {
                    this.updateSelectedAttacker(res.id);
                    this.closeModal();
                });
            }
            else {
                this.updateAttacker({attacker: this.attackerDetail, data}).then((res) => {
                    this.updateSelectedAttacker(res.id);
                    this.closeModal();
                });
            }
        },
        onClickAttackerDelete() {
            var is_ok = confirm("Delete Attacker ?");
            if (is_ok) {
                this.deleteAttacker(this.attackerDetail).then(() => {
                    this.updateSelectedAttacker(null);
                    this.closeModal();
                });
            };
        }
    }
}
</script>