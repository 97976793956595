const TOKEN_KEY = 'user-token'

const TokenService = {
    getToken() {
        return localStorage.getItem(TOKEN_KEY)
    }
}

const AxiosConfig = {
    headers: {
        'Authorization': "Token " + TokenService.getToken()
    }
}

export const PROTO = 'https'
export const API_HOST = 'hallow.ky7az.xyz'

export { TokenService }
export { AxiosConfig }
