<template>
    <div>
        <b-modal id="modal-target" title="Target" hide-footer>
            <div>
                <b-form ref="form_target" @submit.prevent="onClickSubmitForm">
                    <b-row class="mb-1">
                        <b-col cols="3" class="text-right">
                            <label>Name</label>
                        </b-col>
                        <b-col cols="9" class="text-left">
                            <b-form-input name="name" :value="targetDetail && targetDetail.name || null" size="sm" required autofocus></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3" class="text-right">
                            <label>Host</label>
                        </b-col>
                        <b-col cols="9" class="text-left">
                            <b-form-input name="host" :value="targetDetail && targetDetail.host || null" size="sm" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="mb-4">
                        <b-col cols="3" class="text-right">
                            <label>Path</label>
                        </b-col>
                        <b-col cols="9" class="text-left">
                            <b-form-input name="path" :value="targetDetail && targetDetail.path || '/'" size="sm"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row v-if="this.target_id">
                        <b-col>
                            <b-table-simple>
                                <b-thead>
                                    <b-tr>
                                        <b-th class="text-center">Service</b-th>
                                        <b-th class="text-center">Port</b-th>
                                        <b-th></b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr v-for="target_service in targetServices" :key="target_service.id">
                                        <b-td class="text-center">{{target_service.service.name}}</b-td>
                                        <b-td class="text-center">{{target_service.port.number}}:{{target_service.port.protocol}}</b-td>
                                        <b-td>
                                            <b-icon icon="x-square" title="Delete" class="orange" @click="onClickTargetServiceDelete(target_service.id)"></b-icon>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                                <b-tfoot>
                                    <b-tr>
                                        <b-td><b-form-select name="service" v-model="form_service" :options="optionsServices" @input="onInputTargetService($event)" size="sm"></b-form-select></b-td>
                                        <b-td><b-form-select name="port" v-model="form_port" :options="optionsPorts" @input="onInputTargetPort($event)" size="sm"></b-form-select></b-td>
                                        <b-td></b-td>
                                    </b-tr>
                                </b-tfoot>   
                            </b-table-simple>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col align="center">
                            <b-button variant="danger" size="sm" class="m-1" @click="onClickTargetDelete" v-if="targetDetail">Delete</b-button>
                            <b-button type="submit" variant="primary" size="sm" class="m-1" v-if="!targetDetail">Create</b-button>
                            <b-button type="submit" variant="primary" size="sm" class="m-1" v-if="targetDetail">Save</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Vuex from 'vuex'
import slugify from 'slugify';

export default {
    name: 'PentestTargetOpen',
    props: {
        target_id: Number
    },
    data() {
        return {
            form_service: null,
            form_port: null,
            optionsPorts: []
        }
    },
    computed: {
        ...Vuex.mapState('pentest', [
            'services',
            'ports'
        ]),
        ...Vuex.mapGetters('pentest', [
            'getServiceById',
            'getTargetById',
            'getTargetServiceById',
            'getTargetServicesByTargetId',
            'getTasksByTargetId'
        ]),
        targetDetail() {
            return this.getTargetById(this.target_id);
        },
        targetServices() {
            return this.getTargetServicesByTargetId(this.target_id);
        },
        optionsServices() {
            var options = this.services.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: ''});
            return options;
        }
    },
    methods: {
        ...Vuex.mapActions('pentest', [
            'createTarget',
            'updateTarget',
            'deleteTarget',
            'createTargetService',
            'deleteTargetService',
            'updateSelectedProp'
        ]),
        onInputTargetService(service_id) {
            var options = [{value: null, text: ''}];
            if (service_id) {
                var service = this.getServiceById(service_id);
                if (service)
                    var target_service_ports = this.targetServices.filter(target_service => target_service.service.id === service_id).map(x => x.port.id);
                    options = options.concat(service.default_ports.filter(port => !target_service_ports.includes(port.id)).map(x => ({value: x.id, text: x.number + ':' + x.protocol})));
            };
            this.form_port = null;
            this.optionsPorts = options;
        },
        onInputTargetPort(port_id) {
            if (port_id) {
                var data = {
                    target_id: this.target_id,
                    service_id: this.form_service,
                    port_id: port_id
                }
                this.createTargetService(data).then(() => {
                    this.form_service = null;
                    this.form_port = null;
                    this.optionsPorts = [];
                });
            };
        },
        updateSelectedTarget(target_id) {
            this.updateSelectedProp({prop: 'selected_target', value: target_id});
        },
        closeModal() {
            this.$bvModal.hide('modal-target');
        },
        onClickSubmitForm() {
            var data = {
                name: this.$refs.form_target.name.value,
                host: this.$refs.form_target.host.value,
                path: this.$refs.form_target.path.value,
            }
            data.slug = slugify(data.name, {'replacement': '-', 'lower': true});
            if (!this.targetDetail) {
                this.createTarget(data).then((res) => {
                    this.updateSelectedTarget(res.id);
                    this.closeModal();
                });
            }
            else {
                this.updateTarget({target: this.targetDetail, data}).then((res) => {
                    this.updateSelectedTarget(res.id);
                    this.closeModal();
                });
            }
        },
        onClickTargetServiceDelete(target_service_id) {
            var target_service = this.getTargetServiceById(target_service_id);
            if (target_service)
                this.deleteTargetService(target_service);
        },
        onClickTargetDelete() {
            var is_ok = confirm("Delete Target ?");
            if (is_ok) {
                this.deleteTarget(this.targetDetail).then(() => {
                    this.getTasksByTargetId(this.target_id).forEach(task => this.$store.commit('pentest/DELETE_TASK', task));
                    this.updateSelectedTarget(null);
                    this.closeModal();
                });
            };
        }
    }
}
</script>