<template>
    <div>
        <b-modal id="modal-parameter" title="Parameter" hide-footer>
            <div>
                <b-form ref="form_parameter" @submit.prevent="onClickSubmitForm">
                    <b-row class="mb-1">
                        <b-col cols="3" class="text-right">
                            <label>Name</label>
                        </b-col>
                        <b-col cols="9" class="text-left">
                            <b-form-input name="name" :value="parameterDetail && parameterDetail.name || null" size="sm" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3" class="text-right">
                            <label>Key</label>
                        </b-col>
                        <b-col cols="9" class="text-left">
                            <b-form-input name="key" :value="parameterDetail && parameterDetail.key || null" size="sm" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3" class="text-right">
                            <label>Value</label>
                        </b-col>
                        <b-col cols="9" class="text-left">
                            <b-form-textarea name="value" :value="parameterDetail && parameterDetail.value || null" size="sm"></b-form-textarea>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3" class="text-right">
                            <label>Target</label>
                        </b-col>
                        <b-col cols="9" class="text-left">
                            <b-form-select name="target" :value="!parameterDetail && selected_target || parameterDetail && parameterDetail.target && parameterDetail.target.id" :options="optionsTargets" size="sm"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row class="mb-4">
                        <b-col cols="3" class="text-right">
                            <label>Tool</label>
                        </b-col>
                        <b-col cols="9" class="text-left">
                            <b-form-select name="tool" :value="!parameterDetail && selected_tool || parameterDetail && parameterDetail.tool && parameterDetail.tool.id" :options="optionsTools" size="sm"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col align="center">
                            <b-button variant="danger" size="sm" class="m-1" @click="onClickParameterDelete" v-if="parameterDetail">Delete</b-button>
                            <b-button type="submit" variant="primary" size="sm" class="m-1" v-if="!parameterDetail">Create</b-button>
                            <b-button type="submit" variant="primary" size="sm" class="m-1" v-if="parameterDetail">Save</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Vuex from 'vuex'

export default {
    name: 'PentestParameterOpen',
    props: {
        parameter_id: Number
    },
    data() {
        return {
        }
    },
    computed: {
        ...Vuex.mapState('pentest', [
            'targets',
            'tools',
            'selected_target',
            'selected_tool'
        ]),
        ...Vuex.mapGetters('pentest', [
            'getParameterById'
        ]),
        parameterDetail() {
            return this.getParameterById(this.parameter_id);
        },
        optionsTargets() {
            var options = this.targets.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: ''});
            return options;
        },
        optionsTools() {
            var options = this.tools.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: ''});
            return options;
        }
    },
    methods: {
        ...Vuex.mapActions('pentest', [
            'createParameter',
            'updateParameter',
            'deleteParameter',
            'updateSelectedProp'
        ]),
        updateSelectedParameter(parameter_id) {
            this.updateSelectedProp({prop: 'selected_parameter', value: parameter_id});
        },
        closeModal() {
            this.$bvModal.hide('modal-parameter');
        },
        onClickSubmitForm() {
            var data = {
                name: this.$refs.form_parameter.name.value,
                key: this.$refs.form_parameter.key.value,
                value: this.$refs.form_parameter.value.value,
                target_id: this.$refs.form_parameter.target.value,
                tool_id: this.$refs.form_parameter.tool.value
            }
            if (!this.parameterDetail) {
                this.createParameter(data).then((res) => {
                    this.updateSelectedParameter(res.id);
                    this.closeModal();
                });
            }
            else {
                this.updateParameter({parameter: this.parameterDetail, data}).then((res) => {
                    this.updateSelectedParameter(res.id);
                    this.closeModal();
                });
            }
        },
        onClickParameterDelete() {
            var is_ok = confirm("Delete Parameter ?");
            if (is_ok) {
                this.deleteParameter(this.parameterDetail).then(() => {
                    this.updateSelectedParameter(null);
                    this.closeModal();
                });
            };
        }
    }
}
</script>