import Vue from 'vue'
import axios from 'axios';

import {PROTO, API_HOST, AxiosConfig} from '../../storage/service'


const state = {
    attackers: [],
    ports: [],
    services: [],
    targets: [],
    targets_services: [],
    credentials: [],
    parameters: [],
    phases: [],
    vulnerabilities: [],
    tools: [],
    actions: [],
    tasks: [],
    tasks_results: [],

    task_count: 0,
    selected_page: 1,

    search_text: null,
    search_target: null,
    search_params: null,

    selected_attacker: null,
    selected_target: null,
    selected_target_service: null,
    selected_credential: null,
    selected_parameter: null,
    selected_phase: null,
    selected_vulnerability: null,
    selected_tool: null
}

const getters = {
    getAttackerById: (state) => id => state.attackers.find(attacker => attacker.id === id),
    getServiceById: (state) => id => state.services.find(service => service.id === id),
    getTargetById: (state) => id => state.targets.find(target => target.id === id),
    getTargetServiceById: (state) => id => state.targets_services.find(target_service => target_service.id === id),
    getTargetServicesByTargetId: (state) => target_id => state.targets_services.filter(target_service => target_service.target.id === target_id),
    getCredentialById: (state) => id => state.credentials.find(credential => credential.id === id),
    getParameterById: (state) => id => state.parameters.find(parameter => parameter.id === id),
    getActionsByPhaseAndServiceAndVulnerabilityAndTool: (state) => (phase_id, service_id, vulnerability_id, tool_id) => state.actions.filter(action => ((action.phase && action.phase.id || null) === phase_id) && ((action.service && action.service.id || null) === service_id) && ((vulnerability_id && action.vulnerability && action.vulnerability.id || null) === vulnerability_id) && ((tool_id && action.tool && action.tool.id || null) === tool_id)),
    getTaskById: (state) => id => state.tasks.find(task => task.id === id),
    getTasksByTargetId: (state) => target_id => state.tasks.filter(task => task.target.id === target_id),
    getTaskResultByTaskId: (state) => task_id => state.tasks_results.find(task_result => task_result.task_id === task_id)
}

const actions = {
    // Attackers
    async loadAttackers({ commit }) {
        const res = await axios.get(`${PROTO}://${API_HOST}/api/pentest/attackers/`, AxiosConfig);
        commit('SET_ATTACKERS', res.data);
    },
    async createAttacker({ commit }, data) {
        const res = await axios.post(`${PROTO}://${API_HOST}/api/pentest/attackers/`, data, AxiosConfig)
        commit('CREATE_ATTACKER', res.data);
        return res.data;
    },
    async updateAttacker({ commit }, {attacker, data}) {
        const res = await axios.patch(`${PROTO}://${API_HOST}/api/pentest/attackers/${attacker.slug}/`, data, AxiosConfig);
        commit('UPDATE_ATTACKER', res.data);
        return res.data;
    },
    async deleteAttacker({ commit }, attacker) {
        await axios.delete(`${PROTO}://${API_HOST}/api/pentest/attackers/${attacker.slug}/`, AxiosConfig);
        commit('DELETE_ATTACKER', attacker);
    },
    // Ports
    async loadPorts({ commit }) {
        const res = await axios.get(`${PROTO}://${API_HOST}/api/pentest/ports/`, AxiosConfig);
        commit('SET_PORTS', res.data);
    },
    // Services
    async loadServices({ commit }) {
        const res = await axios.get(`${PROTO}://${API_HOST}/api/pentest/services/`, AxiosConfig);
        commit('SET_SERVICES', res.data);
    },
    // Targets
    async loadTargets({ commit }) {
        const res = await axios.get(`${PROTO}://${API_HOST}/api/pentest/targets/`, AxiosConfig);
        commit('SET_TARGETS', res.data);
    },
    async createTarget({ commit }, data) {
        const res = await axios.post(`${PROTO}://${API_HOST}/api/pentest/targets/`, data, AxiosConfig)
        commit('CREATE_TARGET', res.data);
        return res.data;
    },
    async updateTarget({ commit }, {target, data}) {
        const res = await axios.patch(`${PROTO}://${API_HOST}/api/pentest/targets/${target.slug}/`, data, AxiosConfig);
        commit('UPDATE_TARGET', res.data);
        return res.data;
    },
    async deleteTarget({ commit }, target) {
        await axios.delete(`${PROTO}://${API_HOST}/api/pentest/targets/${target.slug}/`, AxiosConfig);
        commit('DELETE_TARGET', target);
    },
    // Targets Services
    async loadTargetsServices({ commit }) {
        const res = await axios.get(`${PROTO}://${API_HOST}/api/pentest/targets_services/`, AxiosConfig);
        commit('SET_TARGETS_SERVICES', res.data);
    },
    async createTargetService({ commit }, data) {
        const res = await axios.post(`${PROTO}://${API_HOST}/api/pentest/targets_services/`, data, AxiosConfig)
        commit('CREATE_TARGET_SERVICE', res.data);
        return res.data;
    },
    async deleteTargetService({ commit }, target_service) {
        await axios.delete(`${PROTO}://${API_HOST}/api/pentest/targets_services/${target_service.id}/`, AxiosConfig);
        commit('DELETE_TARGET_SERVICE', target_service);
    },
    // Credentials
    async loadCredentials({ commit }) {
        const res = await axios.get(`${PROTO}://${API_HOST}/api/pentest/credentials/`, AxiosConfig);
        commit('SET_CREDENTIALS', res.data);
    },
    async createCredential({ commit }, data) {
        const res = await axios.post(`${PROTO}://${API_HOST}/api/pentest/credentials/`, data, AxiosConfig)
        commit('CREATE_CREDENTIAL', res.data);
        return res.data;
    },
    async updateCredential({ commit }, {credential, data}) {
        const res = await axios.patch(`${PROTO}://${API_HOST}/api/pentest/credentials/${credential.id}/`, data, AxiosConfig);
        commit('UPDATE_CREDENTIAL', res.data);
        return res.data;
    },
    async deleteCredential({ commit }, credential) {
        await axios.delete(`${PROTO}://${API_HOST}/api/pentest/credentials/${credential.id}/`, AxiosConfig);
        commit('DELETE_CREDENTIAL', credential);
    },
    // Parameters
    async loadParameters({ commit }) {
        const res = await axios.get(`${PROTO}://${API_HOST}/api/pentest/parameters/`, AxiosConfig);
        commit('SET_PARAMETERS', res.data);
    },
    async createParameter({ commit }, data) {
        const res = await axios.post(`${PROTO}://${API_HOST}/api/pentest/parameters/`, data, AxiosConfig)
        commit('CREATE_PARAMETER', res.data);
        return res.data;
    },
    async updateParameter({ commit }, {parameter, data}) {
        const res = await axios.patch(`${PROTO}://${API_HOST}/api/pentest/parameters/${parameter.id}/`, data, AxiosConfig);
        commit('UPDATE_PARAMETER', res.data);
        return res.data;
    },
    async deleteParameter({ commit }, parameter) {
        await axios.delete(`${PROTO}://${API_HOST}/api/pentest/parameters/${parameter.id}/`, AxiosConfig);
        commit('DELETE_PARAMETER', parameter);
    },
    // Phases
    async loadPhases({ commit }) {
        const res = await axios.get(`${PROTO}://${API_HOST}/api/pentest/phases/`, AxiosConfig);
        commit('SET_PHASES', res.data);
    },
    // Vulnerabilities
    async loadVulnerabilities({ commit }) {
        const res = await axios.get(`${PROTO}://${API_HOST}/api/pentest/vulnerabilities/`, AxiosConfig);
        commit('SET_VULNERABILITIES', res.data);
    },
    // Tools
    async loadTools({ commit }) {
        const res = await axios.get(`${PROTO}://${API_HOST}/api/pentest/tools/`, AxiosConfig);
        commit('SET_TOOLS', res.data);
    },
    // Actions
    async loadActions({ commit }) {
        const res = await axios.get(`${PROTO}://${API_HOST}/api/pentest/actions/`, AxiosConfig);
        commit('SET_ACTIONS', res.data);
    },
    async updateAction({ commit }, {action, data}) {
        const res = await axios.patch(`${PROTO}://${API_HOST}/api/pentest/actions/${action.id}/`, data, AxiosConfig);
        commit('UPDATE_ACTION', res.data);
        return res.data;
    },
    // Tasks
    async loadTasks({ commit }, signal) {
        AxiosConfig['signal'] = signal;
        let req_params = `?page=${state.selected_page}`;
        if (state.search_params)
            req_params += `&${state.search_params}`;
        const res = await axios.get(`${PROTO}://${API_HOST}/api/pentest/tasks/${req_params}`, AxiosConfig);
        commit('SET_TASKS', res.data.results);
        commit('SET_TASK_COUNT', res.data.count);
    },
    async getTaskCommand({ commit }, data) {
        const res = await axios.post(`${PROTO}://${API_HOST}/api/pentest/tasks/task_command/`, data, AxiosConfig)
        return res.data;
    },
    async createTask({ commit }, data) {
        const res = await axios.post(`${PROTO}://${API_HOST}/api/pentest/tasks/`, data, AxiosConfig)
        commit('CREATE_TASK', res.data);
        return res.data;
    },
    async updateTask({ commit }, {task, data}) {
        const res = await axios.patch(`${PROTO}://${API_HOST}/api/pentest/tasks/${task.id}/`, data, AxiosConfig);
        commit('UPDATE_TASK', res.data);
        return res.data;
    },
    async deleteTask({ commit }, task) {
        await axios.delete(`${PROTO}://${API_HOST}/api/pentest/tasks/${task.id}/`, AxiosConfig);
        commit('DELETE_TASK', task);
    },
    // Tasks Results
    async loadTasksResults({ commit }) {
        const res = await axios.get(`${PROTO}://${API_HOST}/api/pentest/tasks/tasks_results/`, AxiosConfig);
        commit('SET_TASKS_RESULTS', res.data);
    },
    // Selected
    updateSelectedProp({ commit }, {prop, value}) {
        commit('SET_SELECTED_PROP', {prop, value});
    },
    // Selected Page
    updateSelectedPage({ commit }, page) {
        commit('SET_SELECTED_PAGE', page);
    },
    // Search Params
    updateSearchParams({ commit }, search) {
        commit('SET_SEARCH_PARAMS', search);
    }
}

const mutations = {
    // Attackers
    SET_ATTACKERS(state, attackers) {
        state.attackers = attackers;
    },
    CREATE_ATTACKER(state, attacker) {
        state.attackers.unshift(attacker);
    },
    UPDATE_ATTACKER(state, attacker) {
        const index = state.attackers.findIndex(x => x.id === attacker.id);
        Vue.set(state.attackers, index, attacker);
    },
    DELETE_ATTACKER(state, attacker) {
        state.attackers = state.attackers.filter(x => x.id !== attacker.id);
    },
    // Ports
    SET_PORTS(state, ports) {
        state.ports = ports;
    },
    // Services
    SET_SERVICES(state, services) {
        state.services = services;
    },
    // Targets
    SET_TARGETS(state, targets) {
        state.targets = targets;
    },
    CREATE_TARGET(state, target) {
        state.targets.unshift(target);
    },
    UPDATE_TARGET(state, target) {
        const index = state.targets.findIndex(x => x.id === target.id);
        Vue.set(state.targets, index, target);
    },
    DELETE_TARGET(state, target) {
        state.targets = state.targets.filter(x => x.id !== target.id);
    },
    // Targets Services
    SET_TARGETS_SERVICES(state, targets_services) {
        state.targets_services = targets_services;
    },
    CREATE_TARGET_SERVICE(state, target_service) {
        state.targets_services.unshift(target_service);
    },
    DELETE_TARGET_SERVICE(state, target_service) {
        state.targets_services = state.targets_services.filter(x => x.id !== target_service.id);
    },
    // Credentials
    SET_CREDENTIALS(state, credentials) {
        state.credentials = credentials;
    },
    CREATE_CREDENTIAL(state, credential) {
        state.credentials.unshift(credential);
    },
    UPDATE_CREDENTIAL(state, credential) {
        const index = state.credentials.findIndex(x => x.id === credential.id);
        Vue.set(state.credentials, index, credential);
    },
    DELETE_CREDENTIAL(state, credential) {
        state.credentials = state.credentials.filter(x => x.id !== credential.id);
    },
    // Parameters
    SET_PARAMETERS(state, parameters) {
        state.parameters = parameters;
    },
    CREATE_PARAMETER(state, parameter) {
        state.parameters.unshift(parameter);
    },
    UPDATE_PARAMETER(state, parameter) {
        const index = state.parameters.findIndex(x => x.id === parameter.id);
        Vue.set(state.parameters, index, parameter);
    },
    DELETE_PARAMETER(state, parameter) {
        state.parameters = state.parameters.filter(x => x.id !== parameter.id);
    },
    // Phases
    SET_PHASES(state, phases) {
        state.phases = phases;
    },
    // Vulnerabilities
    SET_VULNERABILITIES(state, vulnerabilities) {
        state.vulnerabilities = vulnerabilities;
    },
    // Tools
    SET_TOOLS(state, tools) {
        state.tools = tools;
    },
    // Actions
    SET_ACTIONS(state, actions) {
        state.actions = actions;
    },
    UPDATE_ACTION(state, action) {
        const index = state.actions.findIndex(x => x.id === action.id);
        Vue.set(state.actions, index, action);
    },
    // Tasks
    SET_TASKS(state, tasks) {
        state.tasks = tasks;
    },
    SET_TASK_COUNT(state, count) {
        state.task_count = count;
    },
    CREATE_TASK(state, task) {
        state.tasks.unshift(task);
    },
    UPDATE_TASK(state, task) {
        const index = state.tasks.findIndex(x => x.id === task.id);
        Vue.set(state.tasks, index, task);
    },
    DELETE_TASK(state, task) {
        state.tasks = state.tasks.filter(x => x.id !== task.id);
    },
    // Tasks Results
    SET_TASKS_RESULTS(state, tasks_results) {
        state.tasks_results = tasks_results;
    },
    // Selected
    SET_SELECTED_PROP(state, {prop, value}) {
        state[prop] = value;
    },
    // Selected Page
    SET_SELECTED_PAGE(state, page) {
        state.selected_page = page || 1;
    },
    // Search Params
    SET_SEARCH_PARAMS(state, search) {
        let params = [];
        if (search.search_text)
            params.push('command_or_output=' + search.search_text);
        state.search_text = search.search_text;
        if (search.search_target)
            params.push('target=' + search.search_target);
        state.search_target = search.search_target;
        state.search_params = params.join('&');
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}