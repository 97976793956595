<template>
    <div class="pentest">
        <b-container fluid>
            <router-view></router-view>
        </b-container>
    </div>
</template>

<script>
import Vuex from 'vuex'

import { TokenService } from '../storage/service'

export default {
    name: 'Pentest',
    methods: {
        ...Vuex.mapActions('pentest', [
            'loadAttackers',
            'loadPorts',
            'loadServices',
            'loadTargets',
            'loadTargetsServices',
            'loadCredentials',
            'loadParameters',
            'loadPhases',
            'loadVulnerabilities',
            'loadTools',
            'loadActions',
            'loadTasks',
            'loadTasksResults'
        ])
    },
    created() {
        if (TokenService.getToken()) {
            this.loadAttackers();
            this.loadPorts();
            this.loadServices();
            this.loadTargets();
            this.loadTargetsServices();
            this.loadCredentials();
            this.loadParameters();
            this.loadPhases();
            this.loadVulnerabilities();
            this.loadTools();
            this.loadActions();
            this.loadTasks();
            this.loadTasksResults();
        }
    }
}
</script>

<style>
</style>