<template>
    <div>
        <b-row class="mb-4">
            <b-col>
                <b-button to="/pentest">Back</b-button>
            </b-col>
        </b-row>
       <div>
           <PentestAttackerOpen :attacker_id="selected_attacker"/>
           <PentestTargetOpen :target_id="selected_target"/>
           <PentestCredentialOpen :credential_id="selected_credential"/>
           <PentestParameterOpen :parameter_id="selected_parameter"/>
       </div>
        <b-row class="mb-4">
            <b-col lg="6">
                <b-input-group prepend="Attacker" size="sm" class="mb-2">
                    <b-form-select :value="selected_attacker" :options="optionsAttackers" @input="onInputSelected('selected_attacker', $event)"></b-form-select>      
                    <b-input-group-append>
                        <b-button @click="onClickAttacker"><b-icon icon="gear-fill" aria-hidden="true"></b-icon></b-button>
                    </b-input-group-append>
                </b-input-group>
                <b-input-group prepend="Target" size="sm" class="mb-2">
                    <b-form-select :value="selected_target" :options="optionsTargets" @input="onInputSelected('selected_target', $event)"></b-form-select>
                    <b-input-group-append>
                        <b-button @click="onClickTarget"><b-icon icon="gear-fill" aria-hidden="true"></b-icon></b-button>
                    </b-input-group-append>
                </b-input-group>
                <b-input-group prepend="Credential" size="sm" class="mb-2">
                    <b-form-select :value="selected_credential" :options="optionsCredentials" :disabled="selected_target === null" @input="onInputSelected('selected_credential', $event)"></b-form-select>
                    <b-input-group-append>
                        <b-button :disabled="selected_target === null" @click="onClickCredential"><b-icon icon="gear-fill" aria-hidden="true"></b-icon></b-button>
                    </b-input-group-append>
                </b-input-group>
                <b-input-group prepend="Parameter" size="sm" class="mb-2">
                    <b-form-select :value="selected_parameter" :options="optionsParameters" :disabled="selected_target === null" @input="onInputSelected('selected_parameter', $event)"></b-form-select>
                    <b-input-group-append>
                        <b-button :disabled="selected_target === null" @click="onClickParameter"><b-icon icon="gear-fill" aria-hidden="true"></b-icon></b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
            <b-col lg="6">
                <b-input-group prepend="Phase" size="sm" class="mb-2">
                    <b-form-select :value="selected_phase" :options="optionsPhases" :disabled="selected_target === null" @input="onInputSelected('selected_phase', $event)"></b-form-select>         
                </b-input-group>
                <b-input-group prepend="Service" size="sm" class="mb-2">
                    <b-form-select :value="selected_target_service" :options="optionsTargetsServices" :disabled="selected_target === null || selected_phase === null" @input="onInputSelected('selected_target_service', $event)"></b-form-select>
                </b-input-group>
                <b-input-group prepend="Vuln" size="sm" class="mb-2">
                    <b-form-select :value="selected_vulnerability" :options="optionsVulnerabilities" :disabled="selected_target === null || selected_phase === null" @input="onInputSelected('selected_vulnerability', $event)"></b-form-select>
                </b-input-group>
                <b-input-group prepend="Tool" size="sm" class="mb-2">
                    <b-form-select :value="selected_tool" :options="optionsTools" :disabled="selected_target === null || selected_phase === null" @input="onInputSelected('selected_tool', $event)"></b-form-select>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row class="mb-4">
            <b-col>
            </b-col>
            <b-col>
                <b-button size="sm" v-if="selected_actions.length" :disabled="selected_attacker === null || selected_target === null" @click="onClickCopyActions">
                    <b-icon icon="clipboard"></b-icon> Copy Actions
                </b-button>
                &nbsp;
                <b-button size="sm" variant="primary" :disabled="selected_phase === null" @click="onClickSearchActions">
                    <b-icon icon="search"></b-icon> Search Actions
                </b-button>
                &nbsp;
                <b-button size="sm" variant="success" v-if="selected_actions.length" :disabled="selected_attacker === null || selected_target === null" @click="onClickRunActions">
                    <b-icon icon="play-fill"></b-icon> Run Actions
                </b-button>
            </b-col>
            <b-col>
            </b-col>
        </b-row>
        <b-row class="mb-4" v-if="show_text_to_copy">
            <b-form-textarea
                ref="textarea-task-copy"
                v-on:focus="$event.target.select()"
                :value=text_to_copy
            ></b-form-textarea>
        </b-row>
        <b-row>
            <b-col>
                <b-table-simple hover v-if="actions.length">
                    <b-thead>
                        <b-tr>
                            <b-th>
                                <input type="checkbox" v-model="select_all" @click="onClickSelectAllActions">
                            </b-th>
                            <b-th>Name</b-th>
                            <b-th>Vulnerability</b-th>
                            <b-th>Tool</b-th>
                            <b-th>Command</b-th>
                        </b-tr>
                    </b-thead>
                    <draggable tag="tbody" :list="actions" @end="onDragEndActionRow">
                        <b-tr v-for="a in actions" :key="a.id" @click="onClickActionRow(a.id)">
                            <b-td>
                                <label class="form-checkbox">
                                    <input type="checkbox" :value="a.id" v-model="selected_actions">
                                    <i class="form-icon"></i>
                                </label>
                            </b-td>
                            <b-td>{{ a.name }}</b-td>
                            <b-td>{{ a.vulnerability }}</b-td>
                            <b-td>{{ a.tool }}</b-td>
                            <b-td>{{ a.command }}</b-td>
                        </b-tr>
                    </draggable>
                </b-table-simple>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import Vuex from 'vuex'

import PentestAttackerOpen from '@/components/PentestAttackerOpen.vue'
import PentestTargetOpen from '@/components/PentestTargetOpen.vue'
import PentestCredentialOpen from '@/components/PentestCredentialOpen.vue'
import PentestParameterOpen from '@/components/PentestParameterOpen.vue'

export default {
    name: 'PentestTaskCreate',
    components: {
        draggable,
        PentestAttackerOpen,
        PentestTargetOpen,
        PentestCredentialOpen,
        PentestParameterOpen,
    },
    data() {
        return {
            actions: [],
            selected_actions: [],
            select_all: false,
            show_text_to_copy: false,
            text_to_copy: ''
        }
    },
    computed: {
        ...Vuex.mapState('pentest', [
            'attackers',
            'targets',
            'credentials',
            'parameters',
            'phases',
            'services',
            'vulnerabilities',
            'tools',
            'selected_attacker',
            'selected_target',
            'selected_target_service',       
            'selected_credential',
            'selected_parameter',
            'selected_phase',
            'selected_vulnerability',
            'selected_tool'
        ]),
        ...Vuex.mapGetters('pentest', [
            'getTargetById',
            'getTargetServiceById',
            'getTargetServicesByTargetId',
            'getActionsByPhaseAndServiceAndVulnerabilityAndTool'
        ]),
        optionsAttackers() {
            var options = this.attackers.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: ''});
            return options;
        },
        optionsTargets() {
            var options = this.targets.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: ''});
            return options;
        },
        optionsCredentials() {
            var options = this.credentials.map(x => ({value: x.id, text: 'Target = ' + (x.target && x.target.name || 'Global') + ' / Service = ' + (x.service && x.service.name || 'Global') + ' / ' + x.username + ' (' + x.cred_type + ')'}));
            options.unshift({value: null, text: ''});
            return options;
        },
        optionsParameters() {
            var options = this.parameters.map(x => ({value: x.id, text: 'Target = ' + (x.target && x.target.name || 'Global') + ' / Tool = ' +  (x.tool && x.tool.name || 'Global') + ' / ' + x.key}));
            options.unshift({value: null, text: ''});
            return options;
        },
        optionsPhases() {
            var options = this.phases.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: ''});
            return options;
        }, 
        optionsTargetsServices() {
            var options = [{value: null, text: ''}];
            if (this.selected_target) {
                var target = this.getTargetById(this.selected_target);
                if (target)
                    var target_services = this.getTargetServicesByTargetId(target.id);
                    if (target_services)
                        options = options.concat(target_services.map(x => ({value: x.id, text: x.service.name + ' (' + x.port.number + ':' + x.port.protocol + ')'})));
            }
            return options;
        },
        optionsVulnerabilities() {
            var options = this.vulnerabilities.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: 'Any'});
            return options;
        },
        optionsTools() {
            var options = this.tools.map(x => ({value: x.id, text: x.name}));
            options.unshift({value: null, text: 'Any'});
            return options;
        }
    },
    methods: {
        ...Vuex.mapActions('pentest', [
            'updateSelectedProp',
            'updateAction',
            'getTaskCommand',
            'createTask'
        ]),
        onInputSelected(prop, value) {
            this.updateSelectedProp({prop, value});
        },
        onClickAttacker() {
            this.$bvModal.show('modal-attacker');
        },
        onClickTarget() {
            this.$bvModal.show('modal-target');
        },
        onClickCredential() {
            this.$bvModal.show('modal-credential');
        },
        onClickParameter() {
            this.$bvModal.show('modal-parameter');
        },
        onClickSelectAllActions() {
            this.selected_actions = [];
            if (!this.select_all) {
                for (let x in this.actions) {
                    this.selected_actions.push(this.actions[x].id);
                }
            }
        },
        onDragEndActionRow() {
            this.actions.forEach((actionDetail, i) => {
                let data = {priority: i + 1};
                this.updateAction({action: actionDetail, data})
            });
        },
        onClickActionRow(action_id) {
            if (this.selected_actions.includes(action_id)) {
                this.selected_actions.splice(this.selected_actions.indexOf(action_id), 1);
            } else {
                this.selected_actions.push(action_id);
            }
        },
        onClickSearchActions() {
            this.selected_actions = [];
            var phase_id = this.selected_phase || null;
            var target_service_id = this.selected_target_service || null;
            var service_id = target_service_id && this.getTargetServiceById(target_service_id).service.id || null;
            var vulnerability_id = this.selected_vulnerability || null;
            var tool_id = this.selected_tool || null;
            var actions = this.getActionsByPhaseAndServiceAndVulnerabilityAndTool(phase_id, service_id, vulnerability_id, tool_id) || [];
            var actions = _.sortBy(actions, 'priority');
            this.actions = actions.map(a => ({id: a.id, name: a.name, tool: a.tool.name, service: a.service && a.service.name || null, vulnerability: a.vulnerability && a.vulnerability.name || null, auth_needed: a.auth_needed && 'Yes' || 'No', command: a.command}));
        },
        async onClickCopyActions() {
            var commands = [];
            for (let x in this.selected_actions) {
                var data = {
                    attacker_id: this.selected_attacker,
                    target_id: this.selected_target,
                    target_service_id: this.selected_target_service,
                    credential_id: this.selected_credential,
                    action_id: this.selected_actions[x]
                }
                const cmd = await this.getTaskCommand(data);
                commands.push(cmd);
            }
            this.show_text_to_copy = true;
            this.text_to_copy = commands.join('\n');
            await this.$nextTick();
            this.$refs['textarea-task-copy'].focus();
            document.execCommand('copy');
            this.show_text_to_copy = false;
        },
        onClickRunActions() {
            for (let x in this.selected_actions) {
                var data = {
                    attacker_id: this.selected_attacker,
                    target_id: this.selected_target,
                    target_service_id: this.selected_target_service,
                    credential_id: this.selected_credential,
                    action_id: this.selected_actions[x]
                }
                this.createTask(data);
            }
            this.$router.push(`/pentest`);
        }
    }
}
</script>